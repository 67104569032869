// <- MAVI PHOENIX <- BLUMFELD SKIN <- JIRAFA SKIN

@import url('https://fonts.googleapis.com/css2?family=Dokdo&family=Roboto:wght@400;700&display=swap');

body {
  color: #2b2b2b;
  background-color: $mavi-bgcolor;
}

.stage {
  width: 100vw;
  height: 100vh;
  background: url('./assets/images/title_2.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  margin-top: -8rem;
  @include media-breakpoint-down(sm) {
    background: url('./assets/images/title_2_mob.jpg');
    background-size: contain;
    margin-top: -7rem;
    background-repeat: no-repeat;
    background-position: center center;
  }
  a {
    display: block;
    width: 100vw;
    height: 100vh;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.upline,
legend,
.btn {
  text-transform: uppercase;
}

.btn {
  font-family: $headings-font-family;
}

main,
.product-content {
  background-color: $mavi-bgcolor;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

body.home-page {
  color: $mavi-red;
  background-color: $black;
  .product-content,
  main {
    background-color: $black;
  }
  .navbar .nav-link,
  #header .navbar .hamburger {
    color: $white !important;
  }
  h1 {
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
  }
  .navbar-brand {
    display: none;
  }
  .video-container {
    width: 100%;
  }
  .responsive-video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }
}

// NAV

.navbar-light {
  background: transparent !important;
  transition: background 0.2s;
}

.product-listing-page .navbar-brand {
  transition: all 0.1s;
  transition-delay: 0;
}
body[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition-delay: 0.2s;
    transform: translateY(0);
  }
}

#header {
  background-color: transparent;

  .navbar {
    padding: 1rem;
    font-size: $h5-font-size;

    .navbar-brand {
      padding: 0;

      img {
        padding-left: 0.7rem;
        height: 2rem;
        max-height: 2rem;
        @include media-breakpoint-up(sm) {
          height: 3rem;
          max-height: 3rem;
        }
        @include media-breakpoint-up(md) {
          height: 4rem;
          max-height: 4rem;
        }
      }
    }

    .hamburger {
      color: black !important;
      z-index: 3;
      @include media-breakpoint-down(sm) {
        padding-left: 0px;
      }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner:after,
    .hamburger.is-active .hamburger-inner:before {
      background-color: rgba(255, 255, 255) !important;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 3px;
    }

    .fal {
      font-weight: 500;
    }
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  box-shadow: none;
  background-color: hsla(0, 0, 0, 0.7);
  // backdrop-filter: blur(70px);
  top: 0;
  .btn.btn-primary {
    background-color: $mavi-bgcolor;
  }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    font-size: $h2-font-size;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);

    a {
      color: white;

      &:hover {
        color: rgb(179, 179, 179);
      }
    }
  }
}

.offcanvas-collapse-left {
  text-align: right;
  min-width: 320px;
  width: 22vw;
  height: 100vh;
  bottom: auto;
  overflow: visible;
  ul {
    margin-top: 2rem;
  }
  .nav-item {
    padding-right: 1.75rem;
    margin-bottom: 0rem;
    line-height: 0.9;
  }
  .nav-link {
    font-size: $h5-font-size;
  }
  .nav-link:hover {
    color: $gray-600 !important;
  }
  .nav-link.active {
    color: $mavi-bgcolor !important;
  }
}

.offcanvas-collapse-right {
  color: white;
  thead th {
    font-size: $font-size-base !important;
  }

  .close {
    color: white;
    text-shadow: none;

    &:hover {
      color: rgb(179, 179, 179);
    }
  }

  .cart-listing {
    background: rgba(255, 255, 255);
  }
  .coupon-container button {
    color: white !important;

    &:button:hover {
      color: white;
    }
  }
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  // box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $white !important;
  }
}

// MAKI

.maki.card {
  @extend .mb-2;
  .maki-img-container {
    background-color: $mavi-bgcolor;
  }
  .card-title {
    .title {
      font-family: $headings-font-family;
      font-size: $h2-font-size;
      @extend .text-truncate;
    }
    text-align: left;
    @extend .text-truncate;

    .sub-title {
      font-size: smaller;
      font-family: $font-family-sans-serif;
      @extend .text-truncate;
    }
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.1;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.blurry-background-image:before {
  background-image: none;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $white;
  color: $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// NIGIRI FOR TICKETS

.nigiri.card {
  background-color: transparent;
  border: 0;
  // margin-bottom: 0 !important;
  background-color: $white;

  //border-bottom: 1px solid rgba($white, 0.25);
  .nigiri-content {
    padding: 0rem;
    color: $black;
    @include media-breakpoint-up(md) {
      align-items: center;
      grid-template-columns: 15rem 2fr auto;
    }
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    padding: 1.25rem;
    color: $black;
    align-items: center !important;

    // background-image: linear-gradient(to top, black 33%, rgba(255, 255, 255, 0) 0%);
    // background-position: right;
    // background-size: 3px 10px;
    // background-repeat: repeat-y;
    @include media-breakpoint-down(md) {
      background-image: none;
      padding-bottom: 0;
    }
  }

  .nigiri-body {
    font-size: $h2-font-size;
    padding: 1.25rem;
    border-left: 0;
    //text-transform: uppercase;
    font-weight: bold;
    padding-left: 3rem;
    @include media-breakpoint-down(md) {
      padding: 0;
      padding-bottom: 1.25rem;
      margin-bottom: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    font-size: 0.8em;
  }
  .title {
    font-size: $font-size-base * 1.2;
    margin-bottom: 0;
    color: $black;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: $font-size-base;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      //margin-bottom: 0.5rem;
    }
  }
  .location {
    font-weight: 700 !important;
    font-size: $h3-font-size !important;
    text-transform: uppercase;
    color: $black;
    @include media-breakpoint-down(md) {
      //margin-bottom: 1rem;
      padding: 0 1.25rem;
    }
  }
  .nigiri-cta {
    padding: 1.25rem;
    // @include media-breakpoint-down(md) {
    //   background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    //   background-position: top;
    //   background-size: 10px 3px;
    //   background-repeat: repeat-x;
    // }
    .btn.btn-primary {
    }
  }
  &:hover {
    background-color: lighten($primary, 20%);
    .nigiri-date,
    .location {
      color: $white;
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// CART PAGE
.tt-listing {
  background-color: white;
}

// Bundle modal wie Antilopengang
// STYLING PRODUCT-CONFIG

body.product-detail-page {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 600px;
    }
  }
  .radio-list-group,
  .product-thumb {
    box-shadow: none !important;
  }
  .kachel-header {
    background-color: $white;
    border-color: transparent;
    &.active {
      border-color: $primary;
    }
    .media {
      justify-content: center !important;
      flex-direction: column;
      .product-thumb {
        margin-right: 0 !important;
        @extend .mb-2;
        width: 50%;
        height: auto;
        border-radius: 0;
      }
    }
    h5 {
      font-size: 1.2rem !important;
    }
    .custom-radio {
      display: none;
    }
  }
  .kachel-container {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .custom-radio {
      display: none;
    }
    .kachel {
      img {
        display: none;
      }
      background-color: transparent;
      display: flex;
      h5 {
        font-size: 1.2rem !important;
      }
      justify-content: center;
      height: 4rem;
      flex: 1 1 20%;
      &:hover {
        background-color: rgba($gray-300, 0.8);
      }
      &.active {
        background-color: $primary;
        border-color: $primary;
        color: $white;
        font-weight: 700;
      }
    }
  }
}
